import React, { useEffect, useState, useRef } from 'react'
import NoticeBoard from '../components/NoticeBoard'
import CardInfo from '../components/CardInfo'
import NewsShot from '../components/NewsShot'
import TabTittle from '../components/TabTittle'
import Iframe from 'react-iframe'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import SkliPhotoGlry from '../components/skeletonComp/Skli_PgotoGlry'
import ToploaderBar from '../components/ToploaderBar'
import VideoGallery from '../components/VideoGallery'


const videoUrls = [
  // 'https://www.youtube.com/embed/W9SB53E-r6k',
  // 'https://www.youtube.com/embed/l7G3TPz6P24',
  // 'https://www.youtube.com/embed/l_a2YQcG3GU',
  // 'https://www.youtube.com/embed/z6llDxY5JFs',
  // 'https://www.youtube.com/embed/GT9ShGE_qjg',
];

function Home() {
  TabTittle("জেলা পরিষদ, রাজশাহী।")
  const APICoverPhoto = `https://zprajshahi.gov.bd/zpr/data/cover_photo`
  const API_Masquee_text = `https://zprajshahi.gov.bd/zpr/data/slider_text`
  const ApiCoverPhotoUrl = `https://zprajshahi.gov.bd/zpr/assets/cover_photo/`
  const ApiGalleryPhotos = `https://www.zprajshahi.gov.bd/zpr/data/gallery`;
  const APIimgUrl = `https://www.zprajshahi.gov.bd/zpr/assets/zprgallery/`
  const [mainCoverPhhoto, setMainCoverPhoto] = useState([])
  const [isLoading1, setIsLoading1] = useState(true)
  const [mainGalleryPhhoto, setMainGalleryPhhoto] = useState([])
  const [isLoading2, setIsLoading2] = useState(true)
  const [mainMarqueeData, setMarqueeData] = useState([])
  const [isLoadingMarqueeData, setLoadingMarqueeData] = useState(true)
  const fatchAllData = async () => {
    const getCoverPhotoAPI = axios.get(APICoverPhoto)
    const getApiGalleryPhotos = axios.get(ApiGalleryPhotos)
    const getApiMarqueTex = axios.get(API_Masquee_text)

    axios.all([getCoverPhotoAPI, getApiGalleryPhotos, getApiMarqueTex]).then(
      axios.spread((...allAPIData) => {
        const coverPhotoAPIData = allAPIData[0].data
        setMainCoverPhoto(coverPhotoAPIData)
        setIsLoading1(false)
        const GalleryAPIData = allAPIData[1].data
        setMainGalleryPhhoto(GalleryAPIData)
        setIsLoading2(false)
        const MarqueeData = allAPIData[2].data
        setMarqueeData(MarqueeData)
        setLoadingMarqueeData(false)
      })
    )
  }


  useEffect(() => {
    fatchAllData()
  }, [])
  // ------Image Popup------------
  const [model, setModel] = useState(false)
  const [tempimgSrc, setTempimgSrc] = useState('')
  const getSingleImgURL = (url) => {
    setModel(true)
    setTempimgSrc(url)
  }



  return (
    <>
      <ToploaderBar />
      <div className='colam'>
        {
          mainMarqueeData.map(e => {
            return (
              <>
                <marquee behavior="smooth" direction="" className="mb-2 marqeeText" >
                  {e.slider_text}
                </marquee>
              </>
            )
          })
        }
        {/* // <marquee behavior="smooth" direction="" className="mb-2 marqeeText" >
        //   স্বাগতম জেলা পরিষদ, রাজশাহী এর Web Site-এ । ইমেইলঃ info@zprajshahi.org
        // </marquee> */}
        {/* <div className="NewsShortBox">
          <NewsShot />
        </div> */}
        {
          isLoading1
            ?
            <>
              <Skeleton width={'100%'} height={'350px'} />
              <br />
            </>
            :
            <>
              <div className="bigImage">
                <img src={ApiCoverPhotoUrl + mainCoverPhhoto[0].image} alt="" />
              </div>

            </>
        }
        <div className="notifactionPart">
          <NoticeBoard name={"cxvxg"} />
        </div>
        <div className="InfoCardsPart">
          <CardInfo />
        </div>
        <br />
        <div className="photoGy">
          <div className="tittle">
            <h2>ফটো গ্যালারী</h2>
          </div>
          <div className={model ? "modelSingleImg open" : "modelSingleImg"} >
            <div className="cls_btn" onClick={() => setModel(false)} >+</div>
            <div className="img">
              <img src={tempimgSrc} alt="" />
            </div>
          </div>
          <div className="imgBox">
            {
              isLoading2
                ?
                <SkliPhotoGlry />
                :
                <>
                  {
                    mainGalleryPhhoto.splice(0, 4).map((e) => {
                      const { image, s_cat } = e
                      return (
                        <>
                          <div className="card">
                            <div className="imgPart" onClick={() => getSingleImgURL(APIimgUrl + image)} >
                              <img src={APIimgUrl + image} alt="" />
                            </div>
                            <div className="tittle">{s_cat}</div>
                          </div>
                        </>
                      )
                    })
                  }
                </>
            }
          </div>
        </div>
        <VideoGallery videoUrls={videoUrls} />
      </div>
      {/* <div className="optionsPart">
        <div className="comment">
          <img src={CommentImg} alt="" />
        </div>
      </div> */}
    </>
  )
}

export default Home