import React from 'react'
import { Link } from 'react-router-dom'
import MainAPI from '../assets/APIs/mainApi'
import { useState } from 'react'
import TabTittle from '../components/TabTittle'
import Iframe from 'react-iframe'
import ToploaderBar from '../components/ToploaderBar'

function VideoGy() {
    TabTittle('ভিডিও গ্যালারী | জেলা পরিষদ, রাজশাহী।')
    return (
        <>
            <ToploaderBar />
            <div className='colam'>
                <div className="pageTittle">
                    <h2><Link to="/" >Hme</Link>/Videos</h2>
                </div>
                <div className="videoPart">
                    <div className="cstittle my-2">
                        <h2>ভিডিও গ্যালারী</h2>
                    </div>

                    <div className="grid">
                        {/* <div className="videoDiv videoDiv1">
                            <Iframe url='https://www.youtube.com/embed/l_a2YQcG3GU' width='100%' height='100%'></Iframe>
                        </div>
                        <div className="videoDiv videoDiv2">
                            <Iframe url='https://www.youtube.com/embed/l_a2YQcG3GU' width='100%' height='100%'></Iframe>
                        </div>
                        <div className="videoDiv videoDiv3">
                            <Iframe url='https://www.youtube.com/embed/l_a2YQcG3GU' width='100%' height='100%'></Iframe>
                        </div>
                        <div className="videoDiv videoDiv4">
                            <Iframe url='https://www.youtube.com/embed/l_a2YQcG3GU' width='100%' height='100%'></Iframe>
                        </div>
                        <div className="videoDiv videoDiv5">
                            <Iframe url='https://www.youtube.com/embed/l_a2YQcG3GU' width='100%' height='100%'></Iframe>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <div className="optionsPart">
                <div className="comment">
                    <img src={CommentImg} alt="" />
                </div>
            </div> */}
        </>
    )
}

export default VideoGy
